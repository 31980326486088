import React, { useEffect } from "react";
import { useSpring, animated, config } from "react-spring";

interface Props {
  stat: number;
  name: string;
  start: boolean;
}
const AnimatedStat: React.FC<Props> = (props) => {
  const { stat, name, start } = props;

  useEffect(() => {
    start && api.start({ number: stat });
  }, [start]);

  const [spring, api] = useSpring(() => ({ number: 0, config: config.slow }));

  return (
    <div>
      <animated.h2>{spring.number.to((n) => n.toFixed())}</animated.h2>
      <p>{name}</p>
    </div>
  );
};

export default AnimatedStat;

import React from "react";
import styled from "styled-components";
import { DoubleTitle } from "../../styles/ui.styles";
import IconIS from "../../images/svg/increase_speed.inline.svg";
import IconRTA from "../../images/svg/real_time_analysis.inline.svg";
import IconRTS from "../../images/svg/ready_to_sail.inline.svg";
import IconBYH from "../../images/svg/broaden_your_horizons.inline.svg";
import IconACC from "../../images/svg/accessibility.inline.svg";
import IconCS from "../../images/svg/cloud_system.inline.svg";
import IconCL from "../../images/svg/captain_logbook.inline.svg";
import IconACH from "../../images/svg/achievements.inline.svg";
import IconDS from "../../images/svg/data_security.inline.svg";

const FeaturesSection: React.FC = () => {
  return (
    <>
      <Wrapper>
        <DoubleTitle normalTitle="Features" largeTitle="FEATURES" />
        <Grid>
          <div>
            <IconIS />
            <h3>Increase speed</h3>
            <p>
              Through data analytics we deliver insights to improve your
              performance on the water.
            </p>
          </div>

          <div>
            <IconRTA />
            <h3>Real time analysis</h3>
            <p>
              Fastrrr records and analyzes data which is displayed on a
              user-friendly interface .
            </p>
          </div>

          <div>
            <IconRTS />
            <h3>Ready to sail</h3>
            <p>
              Our solution is easy to install and offers a comprehensive
              hardware and software package out of the box.
            </p>
          </div>

          <div>
            <IconBYH />
            <h3>Broaden your horizons</h3>
            <p>
              Connect with fellow sailors and share your nautical experience
              while exploring the seas.
            </p>
          </div>

          <div>
            <IconACC />
            <h3>Accessibility</h3>
            <p>
              We offer a comprehensive yet straightforward data analytics
              interface, which delivers insights for all sailor levels.
            </p>
          </div>

          <div>
            <IconCS />
            <h3>Cloud system</h3>
            <p>
              Our Databay cloud system allows you to save sailing sessions and
              store your race results to get a perfect picture of your
              improvement.
            </p>
          </div>

          <div>
            <IconCL />
            <h3>Captain’s logbook </h3>
            <p>Enjoy the sailing, we do all the paperwork.</p>
          </div>

          <div>
            <IconACH />
            <h3>Achievements</h3>
            <p>
              Complete thrilling tasks by yourself or with fellow sailors to
              improve your sailing skills.
            </p>
          </div>
        </Grid>
      </Wrapper>
      <DataSecurity>
        <IconDS />
        <h3>Data Security</h3>
        <p>
          No one has access to your own sailing data. We store data in encrypted form.
        </p>
      </DataSecurity>
    </>
  );
};

export default FeaturesSection;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px var(--gutter);
  text-align: center;
  h2 {
    position: relative;
    display: block;
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const Grid = styled.div`
  margin-top: 120px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;

  h3,
  p {
    padding: 0 10px;
  }

  @media (max-width: 1320px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DataSecurity = styled.div`
  background: #f3f3f3;
  padding: 20px var(--gutter) 50px var(--gutter);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

import React, { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import HeroSection from "../components/index/heroSection";
import SailStatsSection from "../components/index/sailStatsSection";
import AboutSection from "../components/index/aboutSection";
import SolutionsSection from "../components/index/solutionsSection";
import FeaturesSection from "../components/index/featuresSection";
import InstallSection from "../components/index/installSection";
import Configurator from "../components/configurator/configurator";
import VideoSection from "../components/index/videoSection";

import { useCartStore } from "../lib/store";

const IndexPage: React.FC = () => {
  // update to cart on page load to remove saved items that are currently unavailable
  const { update } = useCartStore();
  useEffect(() => {
    update();
  }, []);

  return (
    <>
      <SEO title={"Home"} description={"Fastrrr home page"} />
      <Layout allowTransparentHeader={true}>
        <HeroSection />
        <SailStatsSection />
        <AboutSection />
        <SolutionsSection />
        <VideoSection />
        <FeaturesSection />
        <Configurator />
        <InstallSection />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default IndexPage;

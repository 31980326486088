// import * from "react" as React;
import React, { useState, useEffect } from "react";
import axios from "axios";
import useInView from "react-cool-inview";
import styled from "styled-components";
import AnimatedStat from "./animatedStat";

interface SailStats {
  boats: number;
  manoeuvres: number;
  loggedDistance: number;
  sailedSessions: number;
}

const SailStatsSection: React.FC = () => {
  const { observe, inView } = useInView({ threshold: 0.75 });

  // * initialize with some values just in case
  const [sailStats, setSailStats] = useState<SailStats>({
    boats: 360,
    manoeuvres: 9800,
    loggedDistance: 123800,
    sailedSessions: 3400,
  });

  // TODO change this url
  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_SITE_URL}/api/get-sail-stats.php`)
      .then((res) => {
        res?.data[0]?.all_boats &&
          res?.data[0]?.all_manoeuvres &&
          res?.data[0]?.logged_distance &&
          res?.data[0]?.sailed_sessions &&
          setSailStats({
            boats: parseInt(res?.data[0]?.all_boats),
            manoeuvres: parseInt(res?.data[0]?.all_manoeuvres),
            loggedDistance: parseInt(res?.data[0]?.logged_distance),
            sailedSessions: parseInt(res?.data[0]?.sailed_sessions),
          });
      })
      .catch((error) => {
        console.log("Error fetching sail stats, ", error);
      });
  }, []);

  return (
    <SailStatWrapper ref={observe}>
      <AnimatedStat
        stat={sailStats?.loggedDistance || 0}
        name={"Logged Distance"}
        start={inView}
      />
      <AnimatedStat
        stat={sailStats?.sailedSessions || 0}
        name={"Sailed Sessions"}
        start={inView}
      />
      <AnimatedStat
        stat={sailStats?.manoeuvres || 0}
        name={"All Manoeuvres"}
        start={inView}
      />
      <AnimatedStat
        stat={sailStats?.boats || 0}
        name={"All Boats"}
        start={inView}
      />
    </SailStatWrapper>
  );
};

export default SailStatsSection;

const SailStatWrapper = styled.section`
  display: grid;
  padding: 0 var(--gutter);
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  background: var(--color-dark-blue);
  color: #fff;

  div {
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      margin-bottom: 1rem;
      font-family: "Azeret Mono", monospace;
    }
  }

  @media (max-width: 780px) {
    grid-template-columns: 1fr 1fr;
  }
`;

import React from "react";
import ReactPlayer from "react-player/lazy";
import styled from "styled-components";
// import video from "../../videos/sail1.mp4";

import useInView from "react-cool-inview";

const VideoSection: React.FC = () => {
  // video will start when 50% is in view
  const { observe, inView } = useInView({ threshold: 0.5 });

  return (
    <PlayerWrapper ref={observe}>
      {/* local version */}
      {/* <ReactPlayer
        playsinline={true}
        loop={true}
        muted={true}
        controls={false}
        playing={true}
        style={{ position: "absolute", top: 0, left: 0 }}
        url={[{ src: video, type: "video/mp4" }]}
        width="100%"
        height="100%"
      /> */}
      {/* youtube version */}

      <ReactPlayer
        url={"https://www.youtube.com/watch?v=acg9yFBwMK8"}
        playsinline={true}
        loop={true}
        muted={true}
        controls={true}
        playing={inView}
        style={{ position: "absolute", top: 0, left: 0 }}
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { showinfo: 0 },
          },
        }}
      />
    </PlayerWrapper>
  );
};

export default VideoSection;

const PlayerWrapper = styled.section`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* // ! Player ratio: 100 / (1280 / 720) */
`;

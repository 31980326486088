import React, { useState } from "react";
import { Link } from "gatsby";
import { useSpring, animated, config } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { times } from "lodash-es";
import styled from "styled-components";
import { PaginationButton } from "../../styles/ui.styles";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import dinghyEditionImg from "../../images/dinghy_edition.jpg";
import yachtEditionImg from "../../images/yacht_edition.jpg";
import coachBoatProImg from "../../images/coach_boat_pro.jpg";
import dataBayImg from "../../images/data_bay.jpg";

const Carousel: React.FC = () => {
  const numberOfElements = 4;
  const rotationStep = (2 * Math.PI) / numberOfElements;
  const scaleFactor = 0.3;
  const opacityFactor = 1;
  const radius = 320;

  const cards = [
    {
      title: "DinghyEdition",
      subtitle:
        "An efficient training experience through data and providing insights...",
      img: dinghyEditionImg,
      link: "/products/dinghy-edition",
    },
    {
      title: "YachtEdition",
      subtitle:
        "Training with keel boats has never been this efficient...",
      img: yachtEditionImg,
      link: "/products/yacht-edition",
    },
    {
      title: "CoachBoatPro",
      subtitle:
        "View your wind information anytime. Fastrrr ignores boat motion...",
      img: coachBoatProImg,
      link: "/products/coach-boat-pro",
    },
    {
      title: "DataBay",
      subtitle:
        "Where data is presented simply and clearly...",
      img: dataBayImg,
      link: "/products/data-bay",
    },
  ];

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [{ current }, api] = useSpring(() => ({
    current: 0,
    config: config.slow,
  }));

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      next();
    },
    onSwipedRight: () => {
      prev();
    },
    trackMouse: true, // ! use true for testing purposes on desktop
  });

  const rotateToElement = (e: React.MouseEvent) => {
    const clickedIndex = e.currentTarget.getAttribute("data-index");
    const currentActiveIndex =
      currentStep <= 0
        ? Math.abs(currentStep % numberOfElements)
        : (numberOfElements - (currentStep % numberOfElements)) %
          numberOfElements;
    if (clickedIndex) {
      let distance = parseInt(clickedIndex) - currentActiveIndex;
      if (distance > numberOfElements / 2) {
        distance = distance - 4;
      }
      if (distance < (-1 * numberOfElements) / 2) {
        distance = distance + 4;
      }
      api.start(() => ({ current: currentStep - distance }));
      setCurrentStep(currentStep - distance);
    }
  };

  const prev = () => {
    api.start(() => ({ current: currentStep + 1 }));
    setCurrentStep(currentStep + 1);
  };
  const next = () => {
    api.start(() => ({ current: currentStep - 1 }));
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <Container {...swipeHandlers}>
        {times(numberOfElements, (i) => {
          return (
            <CarouselAnchor
              key={i}
              style={{
                transform: current.to(
                  (current) =>
                    `
                    translateX(${
                      Math.sin((i + current) * rotationStep) * radius
                    }px)
            
                    scale(${
                      ((Math.cos((i + current) * rotationStep) + 1) / 2) **
                      scaleFactor
                    })`
                ),
                zIndex: current.to((current) =>
                  Math.floor(
                    (1000 * (Math.cos((i + current) * rotationStep) + 1)) / 2
                  )
                ),
                opacity: current.to(
                  (current) =>
                    ((Math.cos((i + current) * rotationStep) + 1) / 2) **
                    opacityFactor
                ),
              }}
            >
              <Item data-index={i} onClick={rotateToElement}>
                <Card>
                  {/* // * cannot use gatsby StaticImage in an array, but these are <40K and loaded immediately anyway 🤷‍♂️ */}
                  <img src={cards[i].img} alt={cards[i].title} />
                  <h3>{cards[i].title}</h3>
                  <p>{cards[i].subtitle}</p>
                  <Link to={cards[i].link}>
                    <div>
                      <FaArrowRight />
                      <p>See more</p>
                    </div>
                  </Link>
                </Card>
              </Item>
            </CarouselAnchor>
          );
        })}
        <Controls>
          <Left>
            <PaginationButton aria-label={"Previous"} onClick={prev}>
              <FaArrowLeft />
            </PaginationButton>
          </Left>
          <Display>
            <div />
            <p>
              0
              {currentStep <= 0
                ? (Math.abs(currentStep) % numberOfElements) + 1
                : ((numberOfElements - (currentStep % numberOfElements)) %
                    numberOfElements) +
                  1}
            </p>
            <div />
          </Display>
          <Right>
            <PaginationButton aria-label={"Next"} onClick={next}>
              <FaArrowRight />
            </PaginationButton>
          </Right>
        </Controls>
      </Container>
    </>
  );
};

export default Carousel;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CarouselAnchor = styled(animated.div)`
  position: relative; // ! important for z index
`;

const Item = styled(animated.div)`
  user-select: none;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Card = styled.div`
  width: 340px;
  height: 520px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(231, 231, 231, 0.32);
  border-radius: 20px;
  padding: 30px;

  img {
    width: 280px;
    height: 280px;
    object-fit: contain;
  }

  h3 {
    font-size: 2.4rem;
  }

  p {
    line-height: 2.2rem;
  }

  a {
    display: block;
    text-decoration: none;
    color: var(--color-orange);
    &:hover {
      color: var(--color-orange-hovered);
    }
  }

  div {
    display: flex;
    p {
      margin: 0 0 0 20px;
      font-weight: 500;
      line-height: 25px;
    }
    svg {
      height: 25px;
    }
  }
  @media (max-width: 370px) {
    width: 300px;
    img {
      width: 240px;
      height: 240px;
    }
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-areas: "left right display ";
  grid-template-columns: 100px 100px 1fr;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1400px) {
    width: 340px;
    grid-template-areas:
      "display display display display"
      ". left  right .";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: 800px) {
    margin-bottom: 40px;
  }
  @media (max-width: 480px) {
    margin-bottom: 64px;
  }
`;
const Left = styled.div`
  grid-area: left;
`;
const Right = styled.div`
  grid-area: right;
`;
const Display = styled.div`
  grid-area: display;
  width: 100%;
  & div {
    flex: 1;
    height: 2px;
    background: #fff;
    margin: 0 20px;
  }
  p {
    text-align: center;
    color: #fff;
    margin: 0 auto;
    width: 100px;
    /* font-size: min(max(3.6rem, calc(3.6rem + ((1vw - 0.48rem) * 5))), 7.2rem); */
    font-size: 3.6rem;
    @media (min-width: 480px) {
      font-size: calc(3.6rem + ((1vw - 0.48rem) * 5));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 7.2rem;
    }
    min-height: 0vw;
    line-height: normal;
  }
  @media (max-width: 1400px) {
  }
`;

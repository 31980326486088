import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Carousel from "./carousel";
import { Button } from "../../styles/ui.styles";

const HeroSection: React.FC = () => {
  return (
    <Wrapper>
      <StaticImage
        style={{ height: "100%" }}
        src={"../../images/landingpage_header.jpg"}
        alt={"Hero background"}
        objectFit="cover"
        layout="fixed"
        objectPosition={"0% 50%"}
        placeholder="blurred"
      />
      <Overlay>
        <Text>
          <Title>
            SCIENCE <br />
            <span>BEHIND</span> SAILING
          </Title>
          <p>
            Fastrrr offers a refined and efficient training experience through
            gathering training data and providing insights based on the AI
            enhanced analytics solution
          </p>
          <div>
            <Link to={"/products"}>
              <Button aria-label={"View products"} primary={true}>
                VIEW PRODUCTS
              </Button>
            </Link>
            {/* <div />
            <Link to={"/about-us"}>
              <WhiteButton aria-label={"Learn more"}>LEARN MORE</WhiteButton>
            </Link> */}
          </div>
        </Text>
        <CarouselWrapper>
          <Carousel />
        </CarouselWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default HeroSection;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--color-dark-blue);
  overflow: hidden;

  @media (max-width: 1400px) {
    height: 1400px;
  }
  @media (max-width: 900px) {
    height: 1300px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, var(--color-dark-blue) 100%),
    rgba(19, 14, 102, 0.46);

  display: flex;

  @media (max-width: 1400px) {
    height: 1400px;
  }
  @media (max-width: 900px) {
    height: 1300px;
  }
`;

const Text = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 50%;
  padding: 100px 0 100px 100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  a {
    text-decoration: none;
  }

  p {
    max-width: 840px;
    color: #fff;
    font-size: min(
      max(1.6rem, calc(1.6rem + ((1vw - 0.48rem) * 1.1111))),
      2.4rem
    );
    min-height: 0vw;
  }
  div {
    display: flex;
  }
  @media (max-width: 1400px) {
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    top: 100px;
    padding: 50px var(--gutter) 0 var(--gutter);
    p {
      margin: 32px auto;
    }

    div {
      margin: 0 auto;
      justify-content: center;
    }
  }
  @media (max-width: 480px) {
    div {
      flex-direction: column;
      width: 100%;
      button {
        width: 100%;
      }
      /* add space between buttons */
      div {
        height: 32px;
      }
    }
  }
`;
const Title = styled.h1`
  margin: 0;
  font-size: min(
    max(3.6rem, calc(3.6rem + ((1vw - 0.48rem) * 8.1944))),
    9.5rem
  );
  min-height: 0vw;
  color: #fff;
  font-weight: 800;

  span {
    color: #fff0;
    margin: 0;
    user-select: none;
    -webkit-text-stroke: 2px #fff;

    @media (max-width: 992px) {
      -webkit-text-stroke: 1px #fff;
    }
  }
`;

const CarouselWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 600px;
  height: 700px;

  @media (max-width: 1400px) {
    width: 100%;
    height: 900px;
  }
`;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import styled from "styled-components";
import { useCartStore } from "../../lib/store";

const SolutionsSection: React.FC = () => {
  const { setUseTypeDefault } = useCartStore();
  return (
    <SolutionsWrapper>
      <div>
        <Link
          onClick={() => setUseTypeDefault("racing")}
          to={"/solutions/racing"}
        >
          <StaticImage src={"../../images/racing.jpg"} alt={"Racing"} />
          <div>
            <h3>
              RACING
              <span />
            </h3>
          </div>
        </Link>
      </div>
      <div>
        <Link
          onClick={() => setUseTypeDefault("leisure")}
          to={"/solutions/leisure"}
        >
          <StaticImage src={"../../images/leisure.jpg"} alt={"Leisure"} />
          <div>
            <h3>
              LEISURE
              <span />
            </h3>
          </div>
        </Link>
      </div>
    </SolutionsWrapper>
  );
};

export default SolutionsSection;

const SolutionsWrapper = styled.section`
  width: 100%;
  display: flex;
  height: 500px;
  transition: 0.2s;
  div {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    & div {
      position: absolute;
      inset: 0;
      background: rgba(248, 51, 28, 0.82);
      transition: 1s;
      display: flex;
      align-items: center;
      & h3 {
        margin-left: var(--gutter);
        font-weight: 500;
        color: #fff;
        display: block;
        & span {
          display: block;
          margin-top: 10px;
          width: 75%;
          height: 4px;
          background: #fff;
          transition: 0.2s;
        }
      }

      &:hover {
        background: rgba(248, 51, 28, 0.4);
        & h3 span {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 992px) {
    height: 800px;
    flex-direction: column;
    div div {
      justify-content: center;
    }
  }
  @media (max-width: 480px) {
    height: 150vw;
  }
`;

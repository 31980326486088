import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Button } from "../../styles/ui.styles";

const AboutSection: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <XLTextAboutUs>
        ABOUT
        <br />
        US
      </XLTextAboutUs>
      <Inner>
        <h2>
          Fastrrr is a unique and innovative performance analysis solution based
          on AI for ALL sailors and coaches
        </h2>
        <Spacer />
        <p>
          We have created a user-friendly interface design which makes the
          analysis clear and understandable for all experience levels of sailors
          and coaches. With our variety of products, the system can be applied
          from the smallest dinghy to the biggest sailboat.  
        </p>
        <Link to={"/about-us"}>
          <BlackButton aria-label={"More about us"}>MORE ABOUT US</BlackButton>
        </Link>
      </Inner>
      <XLTextSolutions>SOLUTIONS</XLTextSolutions>
    </Wrapper>
  );
};

export default AboutSection;

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 20px var(--gutter);
  text-align: center;
  h2 {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  p {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  a {
    text-decoration: none;
  }

  @media (max-width: 480px) {
    text-align: left;
  }
`;

const Inner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const XLText = styled.h1`
  /* font-size: min(max(8rem, calc(8rem + ((1vw - 0.48rem) * 16.6667))), 20rem); */
  font-size: 8rem;
  @media (min-width: 480px) {
    font-size: calc(8rem + ((1vw - 0.48rem) * 16.6667));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 20rem;
  }
  min-height: 0vw;
  font-weight: 800;
  color: #fff0;
  margin: 0;
  user-select: none;
  -webkit-text-stroke: 2px var(--color-orange-hovered);

  @media (max-width: 992px) {
    -webkit-text-stroke: 1px var(--color-orange-hovered);
  }
`;

const XLTextAboutUs = styled(XLText)`
  position: absolute;
  z-index: -1;
  top: 20px;
  transform: translateX(-12%);
  text-align: left;
`;

const Spacer = styled.div`
  width: 200px;
  height: 5px;
  background: var(--color-orange);
  margin: 0 auto;
  @media (max-width: 480px) {
    margin: 0;
  }
`;

const BlackButton = styled(Button)`
  margin: 0 auto 250px auto;
  color: #000;
  border-color: #000;
  &:hover {
    color: rgba(0, 0, 0, 0.7);
    border-color: rgba(0, 0, 0, 0.7);
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const XLTextSolutions = styled(XLText)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 35%) scale(0.7);
`;

import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { DoubleTitle } from "../../styles/ui.styles";
// import video from "../../videos/sail1.mp4";
import { FaCheck } from "react-icons/fa";

const InstallSection: React.FC = () => {
  return (
    <section>
      <Wrapper>
        <Grid>
          <PlayerWrapper>
            {/* local version */}
            {/* <ReactPlayer
              playsinline={true}
              loop={true}
              muted={true}
              controls={false}
              playing={true}
              style={{ position: "absolute", top: 0, left: 0 }}
              url={[{ src: video, type: "video/mp4" }]}
              width="100%"
              height="100%"
            /> */}
            {/* youtube version */}
            <ReactPlayer
              url={"https://www.youtube.com/watch?v=OwFpQLUuUp0"}
              playsinline={true}
              loop={false}
              muted={false}
              controls={true}
              playing={false}
              style={{ position: "absolute", top: 0, left: 0 }}
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: { showinfo: 0 },
                },
              }}
            />
          </PlayerWrapper>
          <ContentWrapper>
            <DoubleTitle
              normalTitle={"Easy to mount, easy to use"}
              largeTitle={"EASY TO"}
            />
            <ul>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Mounting is fast, with a single motion you are ready to sail
                </StyledListElement>
              </li>
              <li>
                <StyledListElement>
                  <FaCheck />
                  There is no need for third party professionals or lengthy
                  preparations
                </StyledListElement>
              </li>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Activate the device with just a smartphone
                </StyledListElement>
              </li>
            </ul>
          </ContentWrapper>
        </Grid>
      </Wrapper>
    </section>
  );
};

export default InstallSection;

const Wrapper = styled.div`
  width: 100%;
`;

const Grid = styled.div`
  padding: 100px var(--gutter);
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* // * aspect ratio: 100 / (1280 / 720) */
`;

const ContentWrapper = styled.div`
  div {
    margin-top: 0;
    margin-bottom: 64px;
  }
  h1 {
    left: 0;
    top: 0;
    font-size: min(9vw, 20rem);
    transform: translate(0px, -70%);
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  @media (max-width: 1200px) {
    grid-row: 1;
    padding: 80px var(--gutter);

    h1 {
      font-size: 16vw;
      left: 50%;
      transform: translate(-50%, -70%);
    }
    p {
      text-align: center;
    }
  }
`;

const StyledListElement = styled.p`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    fill: var(--color-orange);
    margin-right: 16px;
  }
`;
